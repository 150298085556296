<template>
    <Dialog :style="{ width: '300px' }" class="p-fluid" :modal="true">
        <Toast position="bottom-center" group="bc" />
        <template #header>
            <div class="flex justify-content-between align-items-center mb-1 w-full" style="height: 40px">
                <h2 class="text-bluegray-900 m-0">Cadastro de agenda</h2>
            </div>
        </template>
        <div class="grid">
            <div class="col-12">
                <div class="flex flex-column gap-3">
                    <div v-for="opcao in opcoes" :key="opcao.key" class="flex align-items-center">
                        <RadioButton v-model="meioDeContato" :inputId="opcao.key" name="meioDeContato" :value="opcao.key" />
                        <label :for="opcao.key" class="ml-2">{{ opcao.name }}</label>
                    </div>
                </div>
            </div>
            <div class="col-12" v-if="meioDeContato === 'WHATSAPP'">
                <label class="text-lg" for="phone">WhatsApp</label>
                <InputMask id="phone" mask="(99) 99999-9999" v-model.trim="form.phone" autocomplete="off"
                    :class="{ 'p-invalid': submitted && !form.phone }" />
                <small class="p-error" v-if="submitted && !form.phone">WhatsApp é obrigatório.</small>
            </div>
            <div class="col-12" v-else>
                <label class="text-lg" for="email">E-mail</label>
                <InputText id="email" v-model.trim="form.email" autocomplete="off"
                    :class="{ 'p-invalid': submitted && !form.email }" />
                <small class="p-error" v-if="submitted && !form.email">E-mail é obrigatório.</small>
            </div>
            <div class="col-12">
                <label class="text-lg">Link para o cadastro de agenda</label>
                <div class="input-link col-12 grid flex align-items-center justify-content-center align-content-center m-0">
                    <div class="col-11 p-0 text-700 text-lg overflow-hidden text-overflow-ellipsis" style="height: 19px">
                        {{ link }}</div>
                    <i ref="icon" class="p-0 ml-auto text-lg cursor-pointer pi pi-link"
                        v-tooltip.bottom="'Copiar'" @click="copiarLink()"
                        style="font-size: 2rem"></i>
                </div>
            </div>
        </div>
        <div class="flex justify-content-end">
            <Button class="w-3" label="Enviar" :loading="loading" :disabled="loading"
                @click="enviarEmailCadastroAgenda()" />
        </div>
    </Dialog>
</template>
<script>
import { getClientBase } from '@/services/http';

export default {
    props: {
        phone: {
            type: String,
            required: true
        },
        email: {
            type: String,
            required: true
        },
        cnpj: {
            type: String,
            required: true
        },
        companyId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            meioDeContato: 'EMAIL',
            submitted: false,
            loading: false,
            opcoes: [
                { name: 'E-mail', key: 'EMAIL' },
                { name: 'WhatsApp', key: 'WHATSAPP' }
            ],
            form: {
                email: null,
                phone: null,
                cnpj: null,
                companyId: null,
            }
        }
    },
    computed: {
        link() {
            return `${process.env.VUE_APP_WEB}#/cadastro-prestador/`
        }
    },
    mounted() {
        this.form.phone = this.phone;
        this.form.email = this.email;
        this.form.cnpj = this.cnpj;
        this.form.companyId = this.companyId;
    },
    methods: {
        copiarLink() {
            navigator.clipboard.writeText(this.link);
            this.$toast.add({ severity: 'info', summary: 'Link copiado!', group: 'bc', life: 1000 });
        },
        async enviarEmailCadastroAgenda() {
            this.submitted = true;
            const camposValidos = this.validarCampos();
            if (!camposValidos) return;
            try {
                this.loading = true;
                await getClientBase().post('/suppliers/enviar-link-cadastro-agenda', { ...this.form, meioDeContato: this.meioDeContato });
                this.$toast.add({ severity: 'info', summary: 'Link enviado com sucesso!', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: error, life: 3000 });
            }
            this.submitted = false;
            this.loading = false;
        },
        validarCampos() {
            if (this.meioDeContato === 'WHATSAPP' && !this.form.phone) return false;
            return !(this.meioDeContato === 'EMAIL' && !this.form.email);
        }
    }
}
</script>
<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

.input-link {
    background-color: #e8e8e8;
    height: 35.6px;
    border-radius: 6px
}
</style>