<template>
    <Dropdown :disabled="!$checkPermission('cadastro_de_agenda:editar')" v-model="procedimentoSelecionado"
        v-bind="$attrs" :options="procedimentosPadrao" filter showClear aria-autocomplete optionLabel="descricao"
        optionGroupLabel="label" optionGroupChildren="items" placeholder="Selecionar" class="w-full md:w-14rem"
        @filter="filtrar" :optionDisabled="getOptionDisable" :loading="loading">
        <template #optiongroup="{ option }">
            <div class="flex align-items-center" v-if="option.label">
                <div>{{ option.label }}</div>
            </div>
        </template>
    </Dropdown>
</template>
<script setup>
import { onMounted, defineProps, ref, computed, defineEmits } from 'vue';
import { getClientBase } from '@/services/http';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['update:modelValue']);
const toast = useToast();
const props = defineProps({
    opcoesParaDesabilitar: {
        type: Array
    },
    modelValue: {
        type: Object
    }
});

const procedimentoSelecionado = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const procedimentosPadrao = ref([]);
const loading = ref(false);

function getOptionDisable(option) {
    const isOptionInvalid = option?.naoPertenceAoPadrao || option?.naoIdentificado;
    const isOptionSelected = option.id === procedimentoSelecionado.value?.id;
    const isOptionToDisable = props.opcoesParaDesabilitar.includes(option?.id);

    return !(isOptionInvalid || isOptionSelected) && isOptionToDisable;
}

async function getProcedimentosPadrao(filter) {
    try {
        loading.value = true;
        const { data } = await getClientBase().get('/procedimentos-reserva-horario', { params: { filter, page: 1, limit: 10 } });
        data.items.map((p) => (p.isPadrao = true));
        procedimentosPadrao.value = [
            {
                items: [
                    { descricao: 'Não pertence ao padrão', value: 'NAO_PERTENCE_AO_PADRAO', naoPertenceAoPadrao: true },
                    { descricao: 'Não identificado', value: 'NAO_IDENTIFICADO', naoIdentificado: true }
                ]
            },
            {
                label: 'Padrão',
                items: data.items
            }
        ];
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error,
            life: 3000
        });
    } finally {
        loading.value = false;
    }
}

async function filtrar(event) {
    const filter = event.value;
    getProcedimentosPadrao(filter);
}

onMounted(async () => {
    await getProcedimentosPadrao();
});
</script>
