<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        :actionItems="actionItems"
        @onRowSelect="onRowSelect"
        permission="configuracoes_ferias_prestador"
        :expander="false"
        subtitle="Listagem de Férias"
        tooltip="Listagem de Férias"
        nomeTelaDoManual="configuracoes_ferias_prestador-list"
    >
        <template #columns>
            <Column field="nome" header="Nome" sortField="nome" :sortable="true"></Column>
            <Column field="dataInicial" header="Data Inicio" sortField="dataInicial" :sortable="true">
                <template #body="{ data }">
                    {{ $filters.formatDateOnly(data.dataInicial) }}
                </template>
            </Column>
            <Column field="dataFinal" header="Data Fim das Férias" sortField="dataFinal" :sortable="true">
                <template #body="{ data }">
                    {{ $filters.formatDateOnly(data.dataFinal) }}
                </template>
            </Column>
            <Column field="periodo" header="Periodo" sortField="periodo" :sortable="true">
                <template #body="slotProps">
                    {{ renderTipoHorario(slotProps.data?.periodo) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>
<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    emits: ['onEdit'],
    props: ['idPrestador'],
    data() {
        return {
            tenantCustomers: [],
            service: null,
            actionItems: [
                {
                    label: 'Editar',
                    icon: 'pi pi-pencil',
                    disabled: () => !this.$checkPermission(this.$refs.grid.permission + ':editar') && this.$refs.grid.permission,
                    command: () => this.editRecord(this.$refs.grid.record)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    disabled: () => !this.$checkPermission(this.$refs.grid.permission + ':excluir') && this.$refs.grid.permission,
                    command: () => this.$refs.grid.confirmDeleteRecord(this.record)
                }
            ]
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService(`/ferias/prestador/${this.idPrestador}`);
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        },
        editRecord(record) {
            this.$emit('onEdit', record);
        },
        onRowSelect(record) {
            this.$emit('onEdit', record.data);
        },
        renderTipoHorario(tipo) {
            switch (tipo) {
                case 1:
                    return 'Manhã';
                case 2:
                    return 'Tarde';
                case 3:
                    return 'Ambos';
                default:
                    return 'Não Informado.';
            }
        }
    }
};
</script>
<style scoped lang="scss">
:deep(.card) {
    box-shadow: none !important;
}
</style>
