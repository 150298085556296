<template>
    <Dialog
        :visible="visible"
        @update:visible="$emit('update:visible', $event)"
        :header="this.recordPrestador ? 'Férias Coletiva de ' + this.recordPrestador.name : 'Carregando...'"
        :modal="true"
        :closable="true"
        :style="{ width: '80vw' }"
        @hide="$emit('update:visible', false)"
    >
        <div :class="{ 'container-index': temPermissao() }" class="container">
            <div v-if="temPermissao()" class="card shadow-2">
                <CadastroFeriasPrestador
                    ref="cadastroFerias"
                    :idPrestador="this.recordPrestador.id"
                    @onSalvar="reloadListagem"
                ></CadastroFeriasPrestador>
            </div>
            <div class="card p-0 shadow-1">
                <ListagemFeriasPrestador ref="listagemFerias" :idPrestador="this.recordPrestador.id" @onEdit="onEdit"> </ListagemFeriasPrestador>
            </div>
        </div>
    </Dialog>
</template>
<script>
import ListagemFeriasPrestador from './components-ferias-coletivas/ListagemFeriasPrestador.vue';
import CadastroFeriasPrestador from './components-ferias-coletivas/CadastroFeriasPrestador.vue';

export default {
    props: ['recordPrestador', 'visible'],
    emits: ['onClose'],
    components: {
        ListagemFeriasPrestador,
        CadastroFeriasPrestador
    },
    mounted() {},
    methods: {
        onClose() {
            this.$emit('onClose');
        },
        reloadListagem() {
            this.$refs.listagemFerias.load();
        },
        onEdit(record) {
            this.$refs.cadastroFerias.onEdit(record);
        },
        temPermissao() {
            return this.$checkPermission('configuracoes_ferias_prestador:criar');
        }
    }
};
</script>
<style scoped lang="scss">
:deep(.body) {
    max-height: 58vh;
    height: 100%;
}

::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

::-webkit-scrollbar {
    width: 6px;
    background: #f4f4f4;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
}

.container {
    max-height: 75vh;
    height: 75vh;
}

.container-index {
    display: grid;
    gap: 10px;
    grid-template-columns: 250px auto;
    width: 100%;
    max-height: 75vh;
    height: 75vh;
}

.card {
    height: 100%;
    padding: 0px;
}
</style>
