<template>
    <Dialog :style="{ width: '990px' }" class="p-fluid" :modal="true">
        <template #header>
            <div class="flex flex-column mb-1 w-full">
                <h2 class="text-bluegray-900 m-0">Vincular procedimentos para reserva de horário</h2>
            </div>
        </template>
        <template #default>
            <div v-if="!loading">
                <div v-if="procedimentos.length">
                    <span>Para cada nome do <span class="font-bold">procedimento prestador</span> selecione o
                        procedimento referente ao padrão na
                        coluna <span class="font-bold">procedimento padrão</span>, esse processo vai garantir a
                        reserva de horário. Os procedimentos
                        “não identificados” retornaram para o prestador para ajuste so nome.</span>
                    <span>Para cada nome do <span class="font-bold">procedimento prestador</span> selecione o
                        procedimento na coluna <span class="font-bold">vincular procedimentos</span>, esse processo vai
                        garantir a reserva de horário.</span>
                    <DataTable class="mt-4" :value="procedimentos" scrollable scrollHeight="290px"
                        tableStyle="min-width: 50rem">
                        <Column field="descricao_procedimento" header="Procedimento prestador"></Column>
                        <Column field="nome_ambiente" header="Ambiente"></Column>
                        <Column field="antecedencia" header="Dias de antecedência"></Column>
                        <Column field="duracao" header="Duração"></Column>
                        <Column>
                            <template #header>
                                <span class="flex align-items-center">
                                    <span>Vincular procedimentos</span>
                                    <i v-tooltip.top="{
        value: 'Você pode vincular um procedimento ao padrão, retornar para o prestador em casos de “não identificado” ou vincular um procedimento da Duon  com a opção “Não pertence ao padrão”.',
        pt: {
            text: 'w-26rem'
        }
    }" class="pi pi-info-circle" style="font-size: 1.2rem; margin-left: 0.5rem; color: #0891cf" />
                                </span>
                            </template>
                            <template #body="{ index, data }">
                                <DropdownPadraoOpcoes v-model="data.procedimentoPadraoVinculado"
                                    :opcoesParaDesabilitar="getOptionDisablePadrao" :class="{
        'p-invalid':
            v$.procedimentos.$each.$response.$errors[index]?.procedimentoPadraoVinculado?.length &&
            v$.procedimentos.$dirty
    }" />
                            </template>
                        </Column>
                        <Column>
                            <template #header>
                                <span class="flex align-items-center">
                                    <span>Procedimentos Duon</span>
                                    <i v-tooltip.top="{
        value: 'Caso o procedimento não tenha vínculo com o padrão ele deve ser vinculado a algum procedimento da Duon',
        pt: {
            text: 'w-26rem'
        }
    }" class="pi pi-info-circle" style="font-size: 1.2rem; margin-left: 0.5rem; color: #0891cf" />
                                </span>
                            </template>
                            <template #body="{ data }">
                                <DropdownProcedimentosDuon
                                    :disabled="!data?.procedimentoPadraoVinculado?.naoPertenceAoPadrao"
                                    v-model="data.procedimentoDuonVinculado"
                                    :opcoesParaDesabilitar="getOptionDisableDuon" :class="{
        'p-invalid':
            submitted &&
            data?.procedimentoPadraoVinculado?.naoPertenceAoPadrao &&
            !data.procedimentoDuonVinculado?.id
    }" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div v-else class="mt-4">
                    <AppMensagemNotFound :icon="'pi pi-user'"
                        :textoNotFound="'O prestador não tem nenhuma sugestão de procedimento cadastrada ainda!'" />
                </div>
            </div>
            <AppLoadingWrapper v-else />
        </template>
        <template #footer>
            <div v-if="procedimentos.length">
                <Button :loading="loading || loadingButton" :disabled="loadingButton" label="Cancelar"
                    @click="emit('onClose')" class="p-button-text" />
                <Button :disabled="!$checkPermission('cadastro_de_agenda:editar') || loadingButton"
                    :loading="loading || loadingButton" @click="vincularSugestaoAoPadrao" label="Confirmar"
                    class="p-button" />
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, defineProps, onMounted, defineEmits, computed } from 'vue';
import { getClientBase } from '@/services/http';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import DropdownPadraoOpcoes from './DropdownPadraoOpcoes.vue';
import DropdownProcedimentosDuon from './DropdownProcedimentosDuon.vue';

const toast = useToast();
const emit = defineEmits(['onClose']);
const props = defineProps({
    cnpj: {
        type: String
    },
    email: {
        type: String
    }
});
const procedimentos = ref([]);

const loading = ref(false);
const submitted = ref(false);
const loadingButton = ref(false);
const rules = {
    procedimentos: {
        $each: helpers.forEach({
            procedimentoPadraoVinculado: {
                required: helpers.withMessage('Selecione uma opção ', required)
            }
        })
    }
};

const v$ = useVuelidate(rules, { procedimentos });

const getOptionDisablePadrao = computed(() => {
    return procedimentos.value.filter((q) => q.procedimentoPadraoVinculado).map((p) => p.procedimentoPadraoVinculado.id);
});

const getOptionDisableDuon = computed(() => {
    return procedimentos.value.filter((q) => q.procedimentoDuonVinculado).map((p) => p.procedimentoDuonVinculado.id);
});

async function getProcedimentosSugestao() {
    try {
        loading.value = true;
        const { data } = await getClientBase().get(`/procedimentos-sugestao/get-by-cnpj`, {
            params: {
                cnpj: props.cnpj
            }
        });
        data.map((p) => ((p.procedimentoPadraoVinculado = null), (p.procedimentoDuonVinculado = null)));
        procedimentos.value = data;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error,
            life: 3000
        });
    } finally {
        loading.value = false;
    }
}

async function vincularSugestaoAoPadrao() {
    try {
        const vuelidate = await v$.value.$validate();
        const formularioExtra = validarFormulario();
        if (!vuelidate || !formularioExtra) {
            toast.add({
                severity: 'info',
                summary: 'Preencha os campos em destaque.',
                life: 3000
            });
            return;
        }
        loadingButton.value = true;
        await getClientBase().post('/procedimentos-sugestao/vincular-procedimento-sugestao-ao-padrao', {
            procedimentosSugestao: procedimentos.value,
            cnpj: props.cnpj,
            email: props.email
        });
        toast.add({
            severity: 'success',
            summary: 'Solicitação realizada com sucesso!',
            life: 3000
        });
        emit('onClose');
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error,
            life: 3000
        });
    } finally {
        loadingButton.value = false;
    }
}

function procedimentoValido(procedimento) {
    return !(procedimento?.procedimentoPadraoVinculado?.naoPertenceAoPadrao && !procedimento?.procedimentoDuonVinculado?.id);
}

function validarFormulario() {
    submitted.value = true;
    return procedimentos.value.every(procedimentoValido);
}
onMounted(async () => {
    await getProcedimentosSugestao();
});
</script>
<style>
::-webkit-scrollbar-track {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #f4f4f4;
    margin-left: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}

.p-dropdown-item-group:empty {
    display: none !important;
}
</style>
