<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo">
        <template #content>
            <AppFormCadastro :service="service" :form="form" @onBeforeSave="onBeforeSave" @onAfterSave="onAfterSave"
                @onValidate="onValidate">
                <template #content>
                    <h5 class="mb-4">{{ title }}</h5>
                    <div class="card p-0">
                        <div class="field">
                            <label for="name">Nome</label>
                            <InputText id="nome" v-model.trim="form.nome" required="true" autofocus autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.nome }" />
                            <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatória.</small>
                        </div>
                        <div class="field">
                            <label for="rangeDatas">Período de Férias</label>
                            <Calendar id="rangeDatas" inputId="rangeDatas" v-model="rangeDatas" selectionMode="range"
                                :showIcon="true" :class="{ 'p-invalid': submitted && !rangeDatas }" />
                            <small class="p-error" v-if="submitted && !rangeDatas">Período de Férias é
                                obrigatório.</small>
                        </div>
                        <div class="field">
                            <label for="periodo">Período</label>
                            <Dropdown id="periodo" v-model="form.periodo" :options="periodo"
                                optionValue="value" optionLabel="label" placeholder="Selecione o período do dia"
                                :class="{ 'p-invalid': submitted && !form.periodo }" />
                            <small class="p-error" v-if="submitted && !form.periodo">Período é obrigatório.</small>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '@/services/BaseService';
import moment from 'moment-timezone';

export default {
    emits: ['onSalvar'],
    props: ['idPrestador'],
    data() {
        return {
            form: {
                prestadorId: this.idPrestador
            },
            submitted: false,
            rangeDatas: [],
        };
    },
    created() {
        this.service = new BaseService('/ferias/prestador');
    },
    computed: {
        title() {
            return this.form.id ? 'Editar Férias' : 'Incluir Férias';
        },
        periodo() {
            const periodo = [
                { label: 'Manhã', value: 1 },
                { label: 'Tarde', value: 2 },
                { label: 'Ambos', value: 3 }
            ];
            return periodo;
        },
    },
    watch: {
        rangeDatas() {
            this.form.dataInicial = this.rangeDatas[0];
            this.form.dataFinal = this.rangeDatas[1];
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
            this.$emit('onSalvar');
            this.form = {
                prestadorId: this.idPrestador
            };
            this.rangeDatas = [];
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.form.nome) return true;
            if (!this.form.dataFinal) return true;
            if (!this.form.dataFinal) return true;

        },
        async onEdit(record) {
            this.form = record;
            this.rangeDatas[0] = this.form.dataInicial ? moment.tz(this.form.dataInicial, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate() : null;
            this.rangeDatas[1] = this.form.dataFinal ? moment.tz(this.form.dataFinal, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate() : null;
            document.getElementById('rangeDatas').focus();
            document.getElementById('nome').focus();
        },
    }
};
</script>

<style scoped lang="scss">
:deep(.card) {
    box-shadow: none !important;
}
</style>
