<template>
    <div>
        <AppPaginatedGrid ref="grid" :service="service" permission="gestamedica_painel_prestador"
            :extraActionItems="actionItems" @onToggleMenu="onToggleMenu" newPath="/supplier/new" editPath="/supplier/"
            :expander="true" subtitle="Prestadores" tooltip="Cadastro de Prestadores" :hasFiltro="false"
            :filtrosExtras="filtrosExtras" nomeTelaDoManual="supplier-list" origemHistoricoAcao="/suppliers">
            <template v-if="loading" #header>
                <div class="field col-6">
                    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
                </div>
            </template>
            <template #anotherFilters>
                <div class="flex flex-row p-fluid mt-2 md:mt-0 justify-items-between gap-2 lg:w-6">
                    <div class="field w-4">
                        <label for="contratoId">Contrato</label>
                        <Dropdown id="contratoId" v-model="filters.contrato" :options="ativoInativo" optionLabel="label"
                            optionValue="value" placeholder="Selecione..." :showClear="true" class="step6" />
                    </div>
                    <div class="field w-4">
                        <label for="depositoId">Depósito antecipado</label>
                        <Dropdown id="depositoId" v-model="filters.depositoAntecipado" :options="ativoInativo"
                            optionLabel="label" optionValue="value" placeholder="Selecione..." :showClear="true"
                            class="step7" />
                    </div>
                    <div class="field w-4">
                        <label for="cidade">Cidades atendidas</label>
                        <DropdownCidade id="cidade" :filterInputProps="{ 'data-test-id': 'cidade-filter-input' }"
                            v-model="filters.cidadesSelecionadas" class="step8" />
                    </div>
                    <div class="field w-4">
                        <label for="cidade">Pendência procedimentos</label>
                        <Dropdown id="depositoId" v-model="filters.pendenciaPrestador" :options="simNao"
                            optionLabel="label" optionValue="value" placeholder="Selecione..." :showClear="true"
                            class="step7" />
                    </div>
                </div>
                <div class="flex flex-row p-fluid md:mt-0 mb-2 lg:w-6">
                    <Dropdown v-model="filters.pesquisarPor" :options="optionsPesquisarPor" optionLabel="label"
                        optionValue="value" class="mr-2 step9" placeholder="Selecione..." :showClear="true" />

                    <Chips v-model="filters.text" placeholder="Pesquisar" class="w-full step10" />
                    <Button label="Aplicar filtros" icon="pi pi-search"
                        class="p-button p-button-success w-4 ml-2 step11" @click="() => aplicarFiltros()" />
                </div>
            </template>
            <template #columns>
                <Column :sortable="true" sortField="suppliers.id" field="id" header="Cod."></Column>

                <Column :sortable="true" sortField="suppliers.name" field="name" header="Nome">
                    <template #body="slotProps">
                        <div class="flex flex-row">
                            <div class="flex flex-column">
                                {{ slotProps.data.name }}
                                <small class="block text-500">{{ slotProps.data.tradeName }}</small>
                                <small class="block text-500">{{ slotProps.data.code }}</small>
                            </div>
                            <div class="flex flex-column align-items-center justify-content-center">
                                <i v-if="analisarSeTemProcedimentoSugestao(slotProps.data)"
                                    v-tooltip.top="'Pendência ao vincular procedimentos'" class="pi pi-info-circle"
                                    style="font-size: 1.5rem; margin-left: 0.5rem; color: #0891cf" />
                            </div>
                        </div>
                    </template>
                </Column>

                <Column :sortable="true" field="activeContract" header="Contrato" headerStyle="width: 8rem">
                    <template #body="slotProps">
                        <Chip v-if="slotProps.data.activeContract" label="Ativo" class="px-3 text-sm bg-green-200" />
                        <Chip v-else label="Inativo" class="px-3 text-sm" />
                    </template>
                </Column>

                <Column :sortable="true" field="advanceDeposit" header="Depósito antecipado" headerStyle="width: 15rem">
                    <template #body="slotProps">
                        <Chip v-if="slotProps.data.advanceDeposit" label="Ativo" class="px-3 text-sm bg-green-200" />
                        <Chip v-else label="Inativo" class="px-3 text-sm" />
                    </template>
                </Column>

                <Column :sortable="true" sortField="suppliers.prioridade" field="prioridade" header="Classificação"
                    headerStyle="width: 10rem">
                    <template #body="{ data }">
                        {{ enumPrioridadePrestador[data.prioridade] }}
                    </template>
                </Column>

                <Column header="Codigo SOC" :sortable="true" sortField="suppliers.code">
                    <template #body="{ data }">
                        <div v-if="!data.companyId?.integracaoSoc" class="field mt-1 mb-0" style="text-align: center">
                            {{ 'Sem integração' }}
                        </div>
                        <div v-if="data.companyId?.integracaoSoc && !data.code" class="field mt-1 mb-0"
                            style="text-align: center">
                            <i v-tooltip="'Cadastro sem codigo do SOC'" class="pi pi-exclamation-circle"
                                style="font-size: 2rem; color: #ff9900; cursor: pointer" @click="logs(data)" />
                        </div>
                        <div v-if="data.companyId?.integracaoSoc && data.code" class="field mt-1 mb-0"
                            style="text-align: center">
                            {{ data.code }}
                        </div>
                    </template>
                </Column>
            </template>
            <template #expansion="slotProps">
                <div class="m-4">
                    <div class="grid">
                        <div class="col-4">
                            <strong class="block my-2">CNPJ</strong>
                            {{ $filters.cnpj(slotProps.data.cnpj) ?? 'Não informado' }}
                        </div>
                        <div class="col-4">
                            <strong class="block my-2">Horário de Atendimento</strong>
                            <div v-for="(horario, index) in slotProps.data.gradeHorarios" :key="index">
                                {{ horario ? horario : 'Não informado' }}
                            </div>
                        </div>
                        <div class="col-4">
                            <strong class="block my-2">Cidades Atendidas</strong>
                            <div :key="cidade.id" v-for="cidade in slotProps.data.cities">
                                {{ cidade.cidade }}
                            </div>
                        </div>
                    </div>

                    <Divider class="my-4" />

                    <div class="grid">
                        <div class="col">
                            <strong class="block my-2">Endereço</strong>
                            {{ slotProps.data.street }}, {{ slotProps.data.number }}
                            <br />
                            {{ slotProps.data.addressComplement ? slotProps.data.addressComplement : 'Não informado' }}
                            <br />
                            {{ slotProps.data.neighborhood }}, {{ slotProps.data.city.cidade }} / {{
            slotProps.data.city.estado }} <br />
                            {{ slotProps.data.zipCode }}
                        </div>
                        <div class="col">
                            <strong class="block my-2">Telefone</strong>
                            {{ slotProps.data.phone }} <br />
                            {{ slotProps.data.commercialPhone }}
                        </div>
                        <div class="col">
                            <strong class="block my-2">Email</strong>
                            {{ slotProps.data.email }}
                        </div>
                    </div>
                    <Divider class="my-4" />

                    <h5 v-if="slotProps.data.advanceDeposit" class="mt-6 mb-4">Dados para faturamento</h5>
                    <div class="grid" v-if="slotProps.data.advanceDeposit">
                        <div class="col-6">
                            <div class="field">
                                <b>Codigo do Banco: </b>
                                {{ slotProps.data?.codigoBanco ? slotProps.data.codigoBanco : 'Não informado' }}
                            </div>
                            <div class="field">
                                <b>Nome do Banco: </b>
                                {{ slotProps.data?.nomeBanco ? slotProps.data.nomeBanco : 'Não informado' }}
                            </div>
                            <div class="field">
                                <b>Agencia: </b>
                                {{ slotProps.data?.codigoAgencia ? slotProps.data.codigoAgencia : 'Não informado' }}
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="field">
                                <b>Conta: </b>
                                {{ slotProps.data?.contaCorrente ? slotProps.data.contaCorrente : 'Não informado' }}
                            </div>
                            <div class="field">
                                <b>Titular da conta: </b>
                                {{ slotProps.data?.nomeTitularConta ? slotProps.data.nomeTitularConta : 'Não informado'
                                }}
                            </div>
                            <b>CNPJ/CPF do titular: </b>
                            {{
            slotProps.data?.cpfTitularConta || slotProps.data?.cnpjTitularConta
                ? slotProps.data?.cnpjTitularConta + ' ' + slotProps.data?.cpfTitularConta
                : 'Não informado'
        }}
                        </div>
                        <Divider class="my-4" />
                    </div>
                    <h5 class="mt-6 mb-4">Exames Atendidos</h5>

                    <DataTable :value="slotProps.data.exames" class="p-datatable-sm" responsiveLayout="scroll">
                        <Column :sortable="true" field="exame" header="Exame"></Column>
                        <Column :sortable="true" field="custo" header="Custo">
                            <template #body="slotProps">
                                {{ $filters.formatCurrency(slotProps.data.custo) }}
                            </template>
                        </Column>
                        <Column :sortable="true" field="preco" header="Preço">
                            <template #body="slotProps">
                                {{ $filters.formatCurrency(slotProps.data.preco) }}
                            </template>
                        </Column>
                    </DataTable>
                    <h5 class="mt-6 mb-4">Empresas Atendidos</h5>

                    <DataTable :value="slotProps.data.empresas" class="p-datatable-sm" responsiveLayout="scroll">
                        <Column :sortable="true" field="nome" header="Empresas"></Column>
                    </DataTable>
                </div>
            </template>
        </AppPaginatedGrid>
        <Dialog :visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                <span v-if="record">Tem certeza que deseja excluir <b>{{ record.name }}</b>?</span>
            </div>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
                <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger"
                    @click="deleteRecord" />
            </template>
        </Dialog>
        <Dialog :header="'Não Conformidade de ' + prestador.name" :modal="true" :closable="false"
            :visible="openConformidade" :style="{ width: '45vw' }">
            <div class="formgrid grid">
                <div class="field col-12">
                    <label for="customer_id">Tipo de Não Conformidade</label>
                    <Dropdown class="w-full" optionLabel="descricao" v-model="form.tipoNaoConformidadeId" dataKey="id"
                        :options="naoConformidade" placeholder="Selecione um tipo..." />
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12">
                    <label for="customerBranchesId">Agendamento</label>
                    <Dropdown class="w-full" optionLabel="funcionario.name" v-model="form.agendamentoId" dataKey="id"
                        :options="this.agendamentos" placeholder="Selecione um agendamento..." />
                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col-12">
                    <label for="customerBranchesId">Descrição</label>
                    <Textarea class="w-full" v-model="form.descricao" :autoResize="true" rows="5"
                        placeholder="Digite aqui..." />
                </div>
            </div>
            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="openAddNaoConformidade" />
                <Button label="Salvar" icon="pi pi-check" @click="save" autofocus />
            </template>
        </Dialog>
        <AppLogErroDialog v-if="showLogsDialog" :visible="showLogsDialog" title="Logs para o prestador"
            origem="PRESTADOR" :origemId="record.id" @onClose="showLogsDialog = false">
        </AppLogErroDialog>
        <FeriasPrestadorDialog v-if="showFeriasDialog" v-model:visible="showFeriasDialog"
            :recordPrestador="recordPrestador" @onClose="showFeriasDialog = false">
        </FeriasPrestadorDialog>
        <AppAnexoDialog v-if="showAnexoDialog" :visible="showAnexoDialog" title="Anexos do Prestador" tipo="PRESTADOR"
            origem="PRESTADOR" :origemId="recordAnexos.id" @onClickCloseAnexo="showAnexoDialog = false">
        </AppAnexoDialog>
        <EnviarLinkParaCadastroDeAgendaDialog v-if="showEnviarLinkParaCadastroDeAgenda"
            v-model:visible="showEnviarLinkParaCadastroDeAgenda" @onClose="showEnviarLinkParaCadastroDeAgenda = false"
            :phone="recordPrestador.phone" :email="recordPrestador.email" :cnpj="recordPrestador.cnpj"
            :companyId="recordPrestador.companyId.id" />
        <DialogProcedimentosReservaDeHorario v-if="showDialogProcedimentosReservaDeHorario"
            v-model:visible="showDialogProcedimentosReservaDeHorario"
            @onClose="showDialogProcedimentosReservaDeHorario = false" :cnpj="recordPrestador.cnpj"
            :email="recordPrestador.email" />
    </div>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '@/services/store';
import AreaMedicaService from '../../services/AreaMedicaService';
import { addHours, lightFormat } from 'date-fns';
import FeriasPrestadorDialog from './components/FeriasPrestadorDialog.vue';
import DropdownCidade from '../cidades/components/DropdownCidade.vue';
import EnumPrioridadePrestador from '../../enums/EnumPrioridadePrestador';
import EnviarLinkParaCadastroDeAgendaDialog from './components/EnviarLinkParaCadastroDeAgendaDialog.vue';
import DialogProcedimentosReservaDeHorario from './components/procedimentos-reserva-de-horario/DialogProcedimentosReservaDeHorario.vue';

export default {
    components: { FeriasPrestadorDialog, DropdownCidade, EnviarLinkParaCadastroDeAgendaDialog, DialogProcedimentosReservaDeHorario },
    data() {
        return {
            showFeriasDialog: false,
            recordPrestador: null,
            openConformidade: false,
            expandedRows: [],
            selectedProducts: null,
            records: [],
            naoConformidade: [],
            agendamentos: [],
            prestador: [],
            showLogsDialog: false,
            loading: false,
            form: {},
            filtrosExtras: {
                where: [],
                exames: [],
                cidades: [],
                pendenciaPrestador: null
            },
            filters: {
                pesquisarPor: 'suppliers.name'
            },
            page: 1,
            total: 0,
            totalPage: 0,
            perPage: 10,
            filter: [],
            recordDialog: false,
            recordAnexos: {},
            deleteRecordDialog: false,
            showAnexoDialog: false,
            showEnviarLinkParaCadastroDeAgenda: false,
            showDialogProcedimentosReservaDeHorario: false,
            service: null,
            actionItems: [
                {
                    label: 'Horário de atendimento',
                    icon: 'pi pi-pencil',
                    disabled: () => !this.$checkPermission('gestamedica_painel_prestador_procedimento:view'),
                    command: () => this.openHorariosDeAtendimento(this.actionRecord.id)
                },
                {
                    label: 'Prestador procedimento',
                    icon: 'pi pi-pencil',
                    disabled: () => !this.$checkPermission('gestamedica_painel_prestador_procedimento:view'),
                    command: () => this.procedures(this.actionRecord.id)
                },
                {
                    label: 'Prestador procedimento cliente',
                    icon: 'pi pi-pencil',
                    disabled: () => !this.$checkPermission('configuracoes_ferias_prestador:view'),
                    command: () => this.proceduresCustomers(this.actionRecord.id)
                },
                {
                    label: 'Férias Coletiva Prestador',
                    icon: 'pi pi-calendar',
                    disabled: () => !this.$checkPermission('gestamedica_painel_prestador_cliente:view'),
                    command: () => this.openFeriasDialog(this.actionRecord)
                },
                {
                    label: 'Não Conformidade',
                    icon: 'pi pi-exclamation-circle',
                    disabled: () => !this.$checkPermission('gestamedica_painel_prestador:naoconformidade'),
                    command: () => this.openAddNaoConformidade(this.actionRecord)
                },
                {
                    label: 'Anexos',
                    icon: 'pi pi-paperclip',
                    command: () => this.anexos(this.actionRecord),
                    disabled: () => !this.$checkPermission('gestamedica_painel_prestador:view')
                },
                {
                    label: 'Cadastro de agenda',
                    icon: 'pi pi-calendar-plus',
                    command: () => this.openEnviarLinkParaCadastroDeAgenda(this.actionRecord),
                    disabled: () =>
                        !(this.actionRecord?.systemsAndTechnology?.name === 'SOC' && this.$checkPermission('gestamedica_painel_prestador:view'))
                },
                {
                    label: 'Vincular procedimentos',
                    icon: 'pi pi-file',
                    command: () => this.openDialogProcedimentosReservaDeHorario(this.actionRecord),
                    disabled: () => !(this.actionRecord?.systemsAndTechnology?.name === 'SOC' && this.$checkPermission('cadastro_de_agenda:view'))
                }
            ],
            ativoInativo: [
                { label: 'Ativo', value: true },
                { label: 'Inativo', value: false }
            ],
            simNao: [
                { label: 'Sim', value: true },
                { label: 'Não', value: false }
            ],
            optionsPesquisarPor: [
                { label: 'Nome', value: 'suppliers.name' },
                { label: 'Exames', value: 'exame' },
                { label: 'Código', value: 'suppliers.id' },
                { label: 'Código SOC', value: 'code' }
            ],
            enumPrioridadePrestador: EnumPrioridadePrestador
        };
    },
    mounted() {
        this.$serviceTipo = new AreaMedicaService('/tipo_nao_conformidade');
        this.$serviceConformidade = new AreaMedicaService('/nao_conformidade');
        this.$serviceAgendamento = new AreaMedicaService('/agendamentos');
        this.load();
        this.loadNaoConformidade();
        this.loadAgendamentos();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        'filters.contrato'() {
            this.createFiltersWhere();
        },
        'filters.depositoAntecipado'() {
            this.createFiltersWhere();
        },
        'filters.pesquisarPor'() {
            this.createFiltersWhere();
        },
        'filters.cidadesSelecionadas'() {
            this.createFiltersWhere();
        },
        'filters.pendenciaPrestador'() {
            this.createFiltersWhere();
        },
        'filters.text'() {
            this.createFiltersWhere();
            this.load();
        },
        'filtrosExtras.where'(value) {
            const exames = this.filtrosExtras.exames;
            const cidades = this.filtrosExtras.cidades;
            const where = value;
            const pendenciaPrestador = this.filtrosExtras.pendenciaPrestador;
            this.filtrosExtras = {
                where,
                exames,
                cidades,
                pendenciaPrestador
            };
        }
    },
    created() {
        this.service = new BaseService('/suppliers');
    },
    methods: {
        analisarSeTemProcedimentoSugestao(value) {
            const ambienteExames = value?.prestadorConfigReserva?.ambiente?.flatMap((ambiente) => ambiente.ambienteExames) || [];
            return ambienteExames.some((exame) => !exame?.procedimentoSugestao?.procedimentoId);
        },

        createFiltersWhere() {
            const where = [];
            let exames = [];
            const cidades = [];

            switch (this.filters?.pesquisarPor) {
                case 'exame':
                    exames = this.filters?.text;
                    break;
                case 'suppliers.id':
                case 'code':
                    where.push({
                        prop: this.filters?.pesquisarPor,
                        operator: 'equal',
                        values: this.filters?.text
                    });
                    break;
                case 'suppliers.name':
                    where.push({
                        prop: this.filters?.pesquisarPor,
                        operator: 'contains',
                        values: this.filters?.text,
                        IlikeAny: true
                    });
                    break;
            }

            if (this.filters?.contrato !== undefined) {
                where.push({
                    prop: 'active_contract',
                    operator: 'equal',
                    values: [this.filters.contrato]
                });
            }

            if (this.filters?.depositoAntecipado !== undefined) {
                where.push({
                    prop: 'advance_deposit',
                    operator: 'equal',
                    values: [this.filters.depositoAntecipado]
                });
            }

            if (this.filters?.cidadesSelecionadas) {
                cidades.push({
                    values: this.filters.cidadesSelecionadas.id
                });
            }

            this.filtrosExtras = {
                where,
                exames,
                cidades,
                pendenciaPrestador: this.filters?.pendenciaPrestador
            };
        },
        aplicarFiltros() {
            this.load();
        },
        openHorariosDeAtendimento(id) {
            this.$router.push(`${id}/horarios-de-atendimento`);
        },
        openFeriasDialog(record) {
            this.showFeriasDialog = true;
            this.recordPrestador = record;
        },
        anexos(record) {
            this.recordAnexos = record;
            this.showAnexoDialog = true;
        },
        openAddNaoConformidade(data) {
            if (this.openConformidade == false) {
                this.openConformidade = true;
                this.prestador = data;
            } else {
                this.openConformidade = false;
                this.prestador = [];
                this.form = [];
            }
        },
        async logs(data) {
            this.record = data;
            this.showLogsDialog = true;
        },
        onToggleMenu(event, data) {
            this.actionRecord = data;
        },

        editRecord(id) {
            this.$router.push('/supplier/' + id);
        },
        procedures(id) {
            this.$router.push(`${id}/procedures`);
        },
        proceduresCustomers(id) {
            this.$router.push(`${id}/procedures-customers`);
        },
        confirmDeleteRecord(record) {
            this.record = record;
            this.deleteRecordDialog = true;
        },
        async deleteRecord() {
            if (this.record) {
                try {
                    await this.service.remove(this.record.id);
                    this.record = {};
                    this.load();
                    this.deleteRecordDialog = false;
                    this.$toast.add({ severity: 'success', summary: 'Prestador excluído com sucesso!', life: 3000 });
                } catch (err) {
                    console.error(err);
                    this.$toast.add({ severity: 'error', summary: 'Erro ao excluir o prestador!', life: 5000 });
                }
            }
        },
        async loadNaoConformidade() {
            const { data } = await this.$serviceTipo.findAll({});
            this.naoConformidade = data;
        },

        formatDate(dateString) {
            const formatDates = lightFormat(addHours(new Date(dateString), 3), 'dd/MM/yyyy');
            return `${formatDates}`;
        },

        async loadAgendamentos() {
            const appointments = await this.$serviceAgendamento.findAll({});
            appointments.data.items.forEach(
                (item) =>
                (item.funcionario.name =
                    item.funcionario.name + ' - ' + this.formatDate(item.dataSugestaoInicial) + ' - ' + this.formatDate(item.dataSugestaoInicial))
            );
            this.agendamentos = appointments.data.items;
            this.total = appointments.total;
        },
        async save() {
            this.submitted = true;
            this.form.prestadorId = this.prestador.id;
            try {
                await this.$serviceConformidade.save(this.form);
                this.$toast.add({ severity: 'success', summary: 'Não Conformidade salva com sucesso!', life: 3000 });
                this.openConformidade = false;
                this.prestador = [];
                this.form = [];
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar a Não Conformidade!', life: 5000 });
            }
        },

        async load() {
            this.filtrosExtras.where = this.filtrosExtras.where.filter((el) => !el.values?.includes(null));
            this.$refs.grid.load();
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.load();
        },
        openEnviarLinkParaCadastroDeAgenda(record) {
            this.recordPrestador = record;
            this.showEnviarLinkParaCadastroDeAgenda = true;
        },
        openDialogProcedimentosReservaDeHorario(record) {
            this.recordPrestador = record;
            this.showDialogProcedimentosReservaDeHorario = true;
        }
    }
};
</script>
