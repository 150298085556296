<template>
    <Dropdown :disabled="!$checkPermission('cadastro_de_agenda:editar')" v-model="procedimentoSelecionado"
        v-bind="$attrs" :options="procedimentosDuon" filter showClear aria-autocomplete optionLabel="name"
        placeholder="Selecionar" class="w-full md:w-14rem" @filter="filtrar" :optionDisabled="getOptionDisable"
        :loading="loading">
        <template #optiongroup="{ option }">
            <div class="flex align-items-center" v-if="option.label">
                <div>{{ option.label }}</div>
            </div>
        </template>
    </Dropdown>
</template>
<script setup>
import { onMounted, defineProps, ref, computed, defineEmits } from 'vue';
import { getClientBase } from '@/services/http';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['update:modelValue']);
const toast = useToast();
const props = defineProps({
    opcoesParaDesabilitar: {
        type: Array
    },
    modelValue: {
        type: Object
    }
});

const procedimentoSelecionado = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

const procedimentosDuon = ref([]);
const loading = ref(false);

function getOptionDisable(option) {
    const isOptionSelected = option.id === procedimentoSelecionado.value?.id;
    const isOptionToDisable = props.opcoesParaDesabilitar.includes(option?.id);

    return !isOptionSelected && isOptionToDisable;
}

async function getProcedimentosDuon(filter) {
    try {
        loading.value = true;
        const { data } = await getClientBase().get('/procedures/procedimentos/duon', { params: { filter, page: 1, limit: 10 } });
        procedimentosDuon.value = data.items;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error,
            life: 3000
        });
    } finally {
        loading.value = false;
    }
}

async function filtrar(event) {
    const filter = event.value;
    getProcedimentosDuon(filter);
}

onMounted(async () => {
    await getProcedimentosDuon();
});
</script>
